import { SignaturesService } from "../../services/signatures-service";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    token: "",
    requestId: localStorage.getItem("request_id") || "",
    auth: false,
    signatureTransaction: [],
    errors: [],
    status: null,
    authId: localStorage.getItem("auth_id") || "",
    hasAssociation: false,
  },
  actions: {
    getRequestData({ commit }, payload) {
      return new Promise((resolve) => {
        SignaturesService.authCheck(
          payload.requestId,
          payload.token,
          payload.authId
        )
          .then((response) => {
            console.log(payload);
            // Initialize formAnswers as an empty object in localStorage
            localStorage.removeItem('formAnswers');
            localStorage.setItem("access_token", payload.token);
            localStorage.setItem("request_id", payload.requestId);
            commit("setToken", payload.toke);
            commit("setRequestId", payload.requestId);
            commit("setAuth", true);
            commit(
              "setSignatureTransaction",
              response.data.signatureTransaction
            );
            commit("setStatus", response.data.status);
            localStorage.setItem(
              "transaction_id",
              response.data.signatureTransaction.id
            );
            localStorage.setItem(
              "file_name",
              response.data.signatureTransaction.document
                ? response.data.signatureTransaction.document.filename
                : "document"
            );
            localStorage.setItem(
              "type",
              response.data.type ? response.data.type : ""
            );
            localStorage.setItem(
              "active_status",
              response.data.status ? response.data.status : ""
            );
            commit("setErrors", []);
            resolve(response);
          })
          .catch((error) => {
            commit("setAuth", false);
            commit("setSignatureTransaction", []);
            commit("setErrors", error.response);
            localStorage.setItem("file_name", null);
            localStorage.setItem("transaction_id", null);
            localStorage.setItem("access_token", "");
            localStorage.setItem("request_id", "");
            localStorage.setItem("type", "");
            localStorage.setItem("formAnswers", "");  
            resolve(error.response);
          });
      });
    },

    addAuthId({ commit }, payload) {
      return new Promise((resolve) => {
        localStorage.setItem("auth_id", payload);
        commit("setAuthId", payload);
        resolve(payload);
      }).catch((error) => {
        commit("setErrors", error.response);
        commit("setAuthId", "");
        localStorage.setItem("auth_id", "");
      });
    },

    setLoginUser({ commit }, payload) {
      return new Promise((resolve) => {
        localStorage.setItem("auth_user_token", payload.apiToken);
        localStorage.setItem("auth_user_data", {
          firstName: payload.firstName,
        });
        localStorage.setItem(
          "remember_me",
          payload.rememberMe
            ? moment(new Date()).format("DD/MM/YYYY HH:mm:ss")
            : ""
        );

        localStorage.setItem("reviewed_list", "");
        localStorage.setItem("rejected_doc_count", 0);

        resolve(payload);
      }).catch((error) => {
        commit("setErrors", error.response);
        commit("setAuthId", "");
        localStorage.setItem("auth_user_token", "");
        localStorage.setItem("auth_user_data", "");
        localStorage.setItem("remember_me", "");
        localStorage.setItem("rejected_doc_count", 0);
      });
    },

    setCertificateDocList({ commit }, payload) {
      return new Promise((resolve) => {
        commit("setCertificateDocList", payload);
        resolve(payload);
      }).catch(() => {
        commit("setCertificateDocList", []);
      });
    },
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setRequestId(state, payload) {
      state.requestId = payload;
    },
    setAuth(state, payload) {
      state.auth = payload;
    },
    setSignatureTransaction(state, payload) {
      state.signatureTransaction = payload;
    },
    setErrors(state, payload) {
      state.setErrors = payload;
    },
    setStatus(state, payload) {
      state.status = payload;
    },
    setAuthId(state, payload) {
      state.authId = payload;
    },
    setCertificateDocList(state, payload) {
      state.certificateDocList = payload;
    },
    setHasAssociation(state, payload) {
      state.hasAssociation = payload
    }
  },
  getters: {
    token(state) {
      return state.token;
    },

    requestId(state) {
      return state.requestId;
    },

    auth(state) {
      return state.auth;
    },

    signatureTransaction(state) {
      return state.signatureTransaction;
    },

    errors(state) {
      return state.errors;
    },

    status(state) {
      return state.status;
    },

    authId(state) {
      return state.authId;
    },

    certificateDocList(state) {
      return state.certificateDocList;
    },

    hasAssociation(state) {
      return state.hasAssociation
    }
  },
};
