import axios from "axios";
import { HTTP } from "@/services/http-common";
import { baseURL } from "../config/setting";

export const SignaturesService = {
  authCheck(id, token, authId) {
    return axios.get(baseURL + `signatures/${id}/?authid=${authId}`, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: "application/json",
      },
    });
  },

  getSignatureRequestDataAuth(id, citizenId, isAuthCitizenId = false, token = null) {
    if (isAuthCitizenId) {
      return HTTP.get(`signatures/${id}/?authid=${citizenId}`);
    }else{
      return axios.get(baseURL + `signatures/${id}/?authid=${citizenId}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      });
    }
  },

  getSignatureRequestDocument(id, authId) {
    return HTTP.get(`signatures/${id}/document/?authid=${authId}`, {
      responseType: "arraybuffer",
      headers: { Accept: "application/pdf" },
    });
  },

  sendOtp (requestId, data, token) {
    console.log ("data" + data);
    console.log ("this.token" + token);
    return axios.post(`${baseURL}signatures/${requestId}/sign/`, data, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: "application/json",
      },
    });
  
  },
  sendOtpOneTimeAuth(requestId, data, token, authId){
    return axios.post(`${baseURL}signatures/${requestId}/sign/?authid=${authId}`, data, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: "application/json",
      },
    });

  },

  signDocument(requestId, data) {
    return HTTP.post(`signatures/${requestId}/sign/`, data);
  },

  rejectDocument(requestId, data) {
    return HTTP.post(`signatures/${requestId}/reject/`, data);
  },

  getSignatureRequestData(id, authId = null) {
    return HTTP.get(`signatures/${id}/?authid=${authId}`);
  },

  login(username, password) {
    const token = `${username}:${password}`;
    const encodedToken = Buffer.from(token, "utf8").toString("base64");
    return axios.get(baseURL + `users/${username}/`, {
      headers: {
        Authorization: `Basic ${encodedToken}`,
        Accept: "application/json",
      },
    });
  },

  signCertificateSignaturesDocument(apiToken, data, signatureId) {
    return axios.post(`${baseURL}signatures/${signatureId}/sign/`, data, {
      headers: {
        Authorization: `Token ${apiToken}`,
        Accept: "application/json",
      },
    });
  },

  getCertificateSignatureRequests(token, params) {
    return axios.get(baseURL + `signatures/`, {
      params: params,
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  },

  getCertificateSignatureRequestDocument(requestId, token, authId) {
    return axios.get(
      baseURL + `signatures/${requestId}/document/?authid=${authId}`,
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/pdf",
        },
      }
    );
  },

  rejectCertificateDocument(apiToken, data, signatureId) {
    return axios.post(baseURL + `signatures/${signatureId}/reject/`, data, {
      headers: {
        Authorization: `Token ${apiToken}`,
        Accept: "application/json",
      },
    });
  },

  getCertificateSignatureDocument(apiToken, signatureId, authId = null) {
    let url = `${baseURL}signatures/${signatureId}/sign/`;
    if (authId) {
      url = url + `?authId=${authId}`;
    }

    return axios.get(url, {
      headers: {
        Authorization: `Token ${apiToken}`,
        Accept: "application/json",
      },
    });
  },
  getBatchCertificateSignatureRequests(token, authId) {
    return axios.get( `${baseURL}signatures/?authId=${authId}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  },

  downloadEvidenceReport(id) {
    return HTTP.get(`evr/${id}/`, {
      responseType: "arraybuffer",
      headers: { Accept: "application/pdf" },
    });
  },
};
