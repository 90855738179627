import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import axios from 'axios';
import VueMoment from 'vue-moment';
import VueSignaturePad from 'vue-signature-pad';


Vue.prototype.$http = axios

// Localization
import i18n from './utilities/locale/i18n';

// Main style sheet
require('./assets/css/style.css');

// Signature Pad
Vue.use(VueSignaturePad);

Vue.config.productionTip = false

new Vue({
  i18n,
  store,
  router,
  vuetify,  
  VueMoment,
  render: h => h(App)
}).$mount('#app');

Vue.directive('pdf-app-load', {
  bind: function (el) {
    let zoomCount = 0;
    const zoomIn = el.querySelector('#zoomIn')
    const zoomOut = el.querySelector('#zoomOut')    
    const viewerContainer = el.querySelector('#mainContainer #viewerContainer')

    markNoZoom(zoomCount)
    zoomIn.addEventListener('click', () => {
      zoomCount++
      markNoZoom(zoomCount)
    })
    zoomOut.addEventListener('click', () => {
      zoomCount--
      markNoZoom(zoomCount)
    })

    function markNoZoom (count) {
      if(count == 0) {
        viewerContainer.classList.add('no-zoom');
      } else {
        viewerContainer.classList.remove('no-zoom');
      }
    }    
  }
})

Vue.directive('uppercase', {
	update (el) {
		el.value = el.value.toUpperCase()
	},
})