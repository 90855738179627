export default {
    namespaced: true,
    state: {
        isUploadRequiredDocsTemp: false,
        transactionApiToken: false,
        hasAttachments: false
    },
    mutations: {
        setIsUploadRequiredDocsTemp(state, payload) {
            state.isUploadRequiredDocsTemp = payload
        },
        setTransactionApiToken(state, payload) {
            state.transactionApiToken = payload
        },
        setHasAttachments(state, payload) {
            state.hasAttachments = payload
        }
    },
    actions: {
        setTransactionApiToken({ commit }, payload) {
            commit('setTransactionApiToken', payload)
        }
    },

    getters: {
        getIsUploadRequiredDocsTemp(state) {
            return state.isUploadRequiredDocsTemp
        },
        getTransactionApiToken: state => state.transactionApiToken,
        hasAttachments: state => state.hasAttachments,
    }
}