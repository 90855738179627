import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	// Redirection
	{
		path: "/",
		redirect: { name: 'mysignatures' },
		meta: {
			publicPage: false,
		},
	},

	// Login
	{
		path: "/mysignatures",
		name: "mysignatures",
		component: () => import("../components/auth/Login.component"),
		meta: {
			publicPage: false,
		},
	},
	{
		path: "/:association/mysignatures",
		name: "custom-mysignatures",
		component: () => import("../components/auth/Login.component"),
		meta: {
			publicPage: false,
		},
	},

	// General info
	{
		path: "/general/:id",
		name: "general",
		component: () => import("../components/GeneralInformation.component"),
		meta: {
			publicPage: false,
		},
	},
	{
		path: "/:association/general/:id",
		name: "custom-general",
		component: () => import("../components/GeneralInformation.component"),
		meta: {
			publicPage: false,
		},
	},


	// Attachments
	{
		path: "/attachments/:id",
		name: "attachments",
		component: () => import("../components/Attachments.component"),
		meta: {
			publicPage: false,
		},
	},
	{
		path: "/:association/attachments/:id",
		name: "custom-attachments",
		component: () => import("../components/Attachments.component"),
		meta: {
			publicPage: false,
		},
	},


	// Forms Questions
	{
		path: "/forms/:id",
		name: "forms",
		component: () => import("../components/FormQuestions.component"),
		meta: {
			publicPage: false,
		},
	},
	{
		path: "/:association/forms/:id",
		name: "custom-forms",
		component: () => import("../components/FormQuestions.component"),
		meta: {
			publicPage: false,
		},
	},

	// PDF viewer
	{
		path: "/pdf-viewer",
		name: "pdf-viewer",
		component: () => import("../components/PdfView.component"),
		meta: {
			publicPage: false,
		},
	},
	{
		path: "/:association/pdf-viewer",
		name: "custom-pdf-viewer",
		component: () => import("../components/PdfView.component"),
		meta: {
			publicPage: false,
		},
	},

	// Signatures List
	{
		path: "/my-signatures",
		name: "my-signatures",
		component: () => import("../components/DocumentList.component.vue"),
		meta: {
			publicPage: false,
		}
	},
	{
		path: "/:association/my-signatures",
		name: "custom-my-signatures",
		component: () => import("../components/DocumentList.component.vue"),
		meta: {
			publicPage: false,
		}
	},


	// Previous Information
	{
		path: "/document-list",
		name: "document-list",
		component: () => import("../components/multiple/PreviousInformation.component.vue"),
		meta: {
			publicPage: false,
		}
	},
	{
		path: "/:association/document-list",
		name: "custom-document-list",
		component: () => import("../components/multiple/PreviousInformation.component.vue"),
		meta: {
			publicPage: false,
		}
	},



	// Document list review
	{
		path: "/multiple-document-list-review",
		name: "multiple-document-list-review",
		component: () => import("../components/multiple/DocumentListReview.component"),
		meta: {
			publicPage: false,
		},
	},
	{
		path: "/:association/multiple-document-list-review",
		name: "custom-multiple-document-list-review",
		component: () => import("../components/multiple/DocumentListReview.component"),
		meta: {
			publicPage: false,
		},
	},

	// Summary
	{
		path: "/summary",
		name: "summary",
		component: () => import("../components/Summary.component"),
		meta: {
			publicPage: false,
		},
	},
	{
		path: "/:association/summary",
		name: "custom-summary",
		component: () => import("../components/Summary.component"),
		meta: {
			publicPage: false,
		},
	},

	// Reject
	{
		path: "/rejected",
		name: "rejected",
		component: () => import("../components/Rejected.component"),
		meta: {
			publicPage: false,
		},
	},
	{
		path: "/:association/rejected",
		name: "custom-rejected",
		component: () => import("../components/Rejected.component"),
		meta: {
			publicPage: false,
		},
	},

	// Single document view
	{
		path: "/document/:id",
		name: "show-document",
		component: () => import("../components/SingleDocumentView.component"),
		meta: {
			publicPage: false,
		},
	},
	{
		path: "/:association/document/:id",
		name: "custom-show-document",
		component: () => import("../components/SingleDocumentView.component"),
		meta: {
			publicPage: false,
		},
	},

	// Multiple Attachments
	{
		path: "/multiple-attachments/:id",
		name: "multiple-attachments",
		component: () => import("../components/multiple/Attachments.component"),
		meta: {
			publicPage: false,
		},
	},
	{
		path: "/:association/multiple-attachments/:id",
		name: "custom-multiple-attachments",
		component: () => import("../components/multiple/Attachments.component"),
		meta: {
			publicPage: false,
		},
	},

	// Multiple Forms
	{
		path: "/multiple-forms/:id",
		name: "multiple-forms",
		component: () => import("../components/multiple/Forms.component"),
		meta: {
			publicPage: false,
		},
	},
	{
		path: "/:association/multiple-forms/:id",
		name: "custom-multiple-forms",
		component: () => import("../components/multiple/Forms.component"),
		meta: {
			publicPage: false,
		},
	},


	// Multiple Pdf
	{
		path: "/multiple-pdf",
		name: "multiple-pdf",
		component: () => import("../components/multiple/DocumentList.component"),
		meta: {
			publicPage: false,
		},
	},
	{
		path: "/:association/multiple-pdf",
		name: "custom-multiple-pdf",
		component: () => import("../components/multiple/DocumentList.component"),
		meta: {
			publicPage: false,
		},
	},


	// Multiple Request document view
	{
		path: "/multiple-document/:id",
		name: "multiple-show-document",
		component: () => import("../components/multiple/SingleDocumentView.component"),
		meta: {
			publicPage: false,
		},
	},
	{
		path: "/:association/document/:id",
		name: "custom-multiple-show-document",
		component: () => import("../components/multiple/SingleDocumentView.component"),
		meta: {
			publicPage: false,
		},
	},

	// Redirection if has association
	// Note : Please keep this route always as last route to avoid route mismatching with other routes
	{
		path: "/:association",
		redirect: { name: 'custom-mysignatures' },
		meta: {
			publicPage: false,
		},
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	const isLoggedIn = localStorage.getItem("auth_user_token");
	const association = to.params.association;

	if (to.meta.publicPage && !isLoggedIn) {
		console.log('public page');
			// Check if association parameter is available and route accordingly
			if (association) {
					next({ name: 'custom-mysignatures', params: { association } });
			} else {
					next({ name: 'mysignatures' });
			}
	} else {
			next();
	}
});


export default router;
