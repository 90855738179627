import Vue from 'vue';
import Vuex from 'vuex';
import common from './modules/common';
import formsModule from './modules/forms';
import attachments from './modules/attachments';

Vue.use(Vuex);

export default new Vuex.Store({
  state: { language: 'en' },
  mutations: {
    changeLanguage(state, language) {
      state.language = language;
    },
  },
  actions: {},
  modules: {
    common,
    forms: formsModule,
    attachments
  },
});
