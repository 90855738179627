import axios from "axios";
import { baseURL } from "../config/setting";

export const HTTP = axios.create({
  baseURL: baseURL
});

export const apiToken = localStorage.getItem("auth_user_token");

HTTP.interceptors.request.use(
  async config => {
    config.headers["Authorization"] = `Token ${localStorage.getItem(
      "access_token"
    )}`;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
